.section-latest-news
  background-color: #222222

  &__container
    &--title
      padding-top: 7.5rem
      font-family: $default_font
      font-size: 4.8rem
      font-weight: 600
      line-height: 5.2rem
      text-align: center
      color: #DCFF4F

    &--flux
      width: 100%
      margin: 7rem auto 0
      display: flex
      justify-content: center

    &--cta
      margin-top: 7.6rem
      display: flex
      justify-content: center
      align-items: center
      width: 100%

      a
        cursor: pointer
        position: relative
        width: max-content
        max-width: 100%
        color: $color_black
        font-family: $default_font
        border-radius: .8rem
        overflow: hidden
        font-size: 1.6rem
        font-weight: 500
        line-height: 2rem
        text-align: center
        transition: 300ms all ease-out
        padding: .3rem

        .border
          position: absolute
          display: block
          width: 100%
          height: 100%
          top: 0
          left: 0
          border: .6rem solid
          border-image-slice: 3
          border-image-source: linear-gradient(97.07deg, #082856 49.28%, #1D69A5 65.99%, #2196F3 85.39%, rgba(220, 255, 79, 0.8) 101.02%)
          transition: 300ms all ease-out

        .text
          z-index: 1
          position: relative
          display: block
          padding: 1.7rem 3.6rem
          background-color: $color_white
          color: $color_black
          border-radius: .8rem
          transition: 300ms all ease-out

        &:hover
          .border
            animation: rotateGradient 1s linear infinite

          .text
            background-color: $color_black
            color: $color_white
