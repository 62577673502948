.section-mantras
  display: flex
  justify-content: center
  margin: 7.5rem auto 0
  background-color: #222222

  &__container
    display: flex
    flex-direction: column
    align-items: center
    padding: 5.6rem 1.6rem 0 !important

    &--title
      width: 100%
      color: $color_white
      font-family: $default_font
      font-size: 3.8rem
      font-weight: 600
      line-height: 4.2rem
      text-align: center

      +mediaMinWidth(992px)
        font-size: 4.8rem
        line-height: 5.2rem

    &--flux
      width: 100%
      margin: 6.5rem auto 0

      .flux-items
        width: 100%
        display: flex
        row-gap: 2.6rem
        margin: 0 auto

        .item
          width: 100%
          min-height: 40rem
          display: flex
          flex-direction: column
          gap: 1.4rem
          margin: 0 auto

          +mediaMinWidth(992px)
            flex-direction: row
            gap: 2.8rem

            &:nth-of-type(even)
              flex-direction: row-reverse

          &__content,
          &__image
            width: 100%
            border-radius: 2.4rem
            background-color: #fff

            +mediaMinWidth(992px)
              width: calc(100% / 2)

          &__content
            padding: 10.8rem 9.8rem 6rem 9.8rem

            +mediaMaxWidth(1095px)
              padding: 7rem

            +mediaMaxWidth(480px)
              padding: 3rem

            &--tags
              display: flex
              column-gap: .8rem

              span
                font-family: $default_font
                font-size: 1.6rem
                font-weight: 500
                line-height: 1.6rem
                color: #C0C0C0
                letter-spacing: 0.01em

            &--title
              margin-top: 1.5rem
              color: #222222
              font-family: $default_font
              font-size: 3.5rem
              font-weight: 500
              line-height: 4.4rem

            &--text
              margin-top: 3.2rem
              color: #5F5F5F
              font-family: $default_font
              font-size: 1.8rem
              font-weight: 400
              line-height: 2.8rem

            &--cta
              margin-top: 3rem
              display: flex
              justify-content: flex-end

              +mediaMaxWidth(1160px)
                justify-content: flex-start

              a
                padding: 1.7rem 3.1rem
                color: #FFFFFF
                border-radius: .8rem
                background-color: #2196F3
                border: none
                font-family: $default_font
                font-size: 1.6rem
                font-weight: 500
                line-height: 2rem
                transition: all 300ms ease-in-out

                &:hover
                  background-color: #072754
                  color: $color_white

          &.simplicity
            .item
              &__content
                &--tags
                  .simplicity
                    color: #2196F3

                &-cta
                  a
                    &:not(:hover)
                      background-color: #2196F3

          &.efficiency
            .item
              &__content
                &--tags
                  .efficiency
                    color: #6161FD

                &--cta
                  a
                    &:not(:hover)
                      color: $color_white
                      background-color: #6161FD

          &.security
            .item
              &__content
                &--tags
                  .security
                    color: #00579E

                &--cta
                  a
                    &:not(:hover)
                      background-color: #DCFF4F
                      color: #222222

          &__image
            overflow: hidden

            img
              width: 100%
              height: 100%
              object-fit: cover
