.section-testimonials
  margin: 10rem auto 0
  display: flex
  justify-content: center

  &__container
    display: flex
    flex-direction: column
    align-items: center
    width: 100%

    .testimonials
      width: 100%
      height: 100%
      margin: 0 auto

      .swiper-testimonials
        width: 100%
        height: 100%
        overflow: hidden

        .swiper-wrapper
          width: 100%
          height: 100%
          margin: 0 auto
          .swiper-slide
            width: 100%
            height: 100%
            overflow: hidden
            background-color: $color_white

            .item
              width: 100%
              height: auto
              display: flex
              flex-direction: column
              row-gap: 3.2rem
              margin: 0 auto

              +mediaMinWidth(992px)
                display: grid
                grid-template-columns: repeat(2, 1fr)
                height: 100%
                row-gap: 0
                column-gap: 3.2rem

              & > *
                width: 100%
                height: 100%
                border-radius: 2.4rem
                background-color: $color_white

              &__image
                width: 100%
                background-color: #EEEEEE
                border: .1rem solid $color_black
                border-radius: 2.4rem
                overflow: hidden

                +mediaMaxWidth(991.98px)
                  height: 43.6rem

                &--top
                  width: 100%
                  padding: 3.3rem 2.4rem
                  +displayGrid(1fr 24px 24px 24px, 22px, 22px)

                  .icon-bar
                    max-width: 39.4rem
                    width: 100%
                    background-color: $color_white
                    border: .1rem solid #0000001A
                    border-radius: 5.8rem

                  .icon-point
                    width: 2.4rem
                    max-width: 100%
                    height: 2.4rem
                    border-radius: 50%

                    &.yellow
                      background-color: #DCFF4F

                    &.blue
                      background-color: #2196F3

                    &.dark-blue
                      background-color: #072754


                &--bottom
                  width: 100%
                  height: 100%
                  max-height: calc(100% - 9rem)
                  border-top: .1rem solid $color_black
                  padding: 2.4rem
                  +displayFlex(row, center, center)
                  color: $color_black
                  font-style: italic

                  img
                    width: 100%
                    height: 100%
                    object-fit: cover
                    object-position: top
                    border-radius: .8rem
                    max-height: 42.1rem

              &__content
                padding: 2.8rem
                background-color: #222222
                display: flex
                flex-direction: column
                justify-content: center

                +mediaMinWidth(521px)
                  padding: 4.8rem

                &--quote
                  margin-bottom: 2.4rem

                  .text
                    margin-top: 2.4rem
                    color: $color_white
                    font-family: $default_font
                    font-size: 2.2rem
                    font-style: italic
                    font-weight: 500
                    line-height: 3.6rem

                &--separator
                  margin-top: auto
                  height: .1rem
                  width: 100%
                  border-top: .1rem dashed #DBF477

                &--description
                  display: flex
                  flex-wrap: wrap
                  gap: 2.5rem
                  margin-top: 5.6rem
                  align-items: center

                  .picture
                    min-width: 9.6rem
                    width: 9.6rem
                    height: 9.6rem
                    border-radius: 50%
                    overflow: hidden

                    img
                      width: 100%
                      height: 100%
                      object-fit: cover

                  .names
                    max-width: 21rem

                    &-person
                      color: $color_white
                      font-family: $default_font
                      font-size: 2.2rem
                      font-weight: 500
                      line-height: 3.2rem

                    &-society
                      font-family: $default_font
                      font-size: 2rem
                      font-weight: 400
                      line-height: 3.2rem
                      color: #D9D9D9B2

                  .arrows
                    display: grid
                    grid-template-columns: repeat(2, 4.8rem)
                    column-gap: 1.6rem
                    margin-top: auto
                    margin-left: auto

                    .swiper-button-prev, .swiper-button-next
                      all: unset
                      padding: 1.8rem
                      display: flex
                      justify-content: center
                      align-items: center
                      border-radius: 50%
                      border: .1rem solid #DCFF4F
                      background-color: transparent
                      cursor: pointer
                      transition: all 300ms ease-in-out

                      &:after
                        transition: all 300ms ease-in-out

                    .swiper-button-prev, .swiper-button-next
                      &::after
                        font-size: 1.2rem

                    .swiper-button-prev
                      &::after
                        color: #DCFF4F

                    .swiper-button-next
                      background-color: #DCFF4F

                      &::after
                        color: #222222

                    .swiper-button-prev,
                    .swiper-button-next
                      &:hover
                        background-color: #DCFF4F

                        &:after
                          color: #222222
