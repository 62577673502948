.flux-items
  all: unset
  display: flex
  flex-direction: row
  flex-wrap: wrap
  width: 100%
  max-width: 1192px
  margin: 0 auto
  gap: 2rem
  row-gap: 5.3rem
