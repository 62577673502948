.section-start-here
  background-color: #222222
  margin: 0 auto
  padding: 7.1rem 0

  +mediaMinWidth(1065px)
    padding: 7.1rem 1.6rem 13rem

  &__container
    min-height: 56rem
    background-color: #5F5F5F
    display: grid
    grid-template-columns: repeat(2, 1fr)
    border-radius: 2.4rem
    position: relative
    overflow: hidden

    +mediaMinWidth(992px)
      padding: 3.7rem 7rem !important

    +mediaMaxWidth(1065px)
      grid-template-columns: repeat(1, 100%)
      min-height: unset
      //height: 450px
      width: 100%
      border-radius: 0
      padding: 2rem
      height: 50rem


    &--left
      z-index: 1
      position: relative
      max-width: 44.3rem

      +mediaMaxWidth(1065px)
        width: 100%
        max-width: 100%
        margin: 0 auto
        text-align: center

      .title
        margin-top: 6.5rem
        font-family: $default_font
        font-size: 6.4rem
        font-weight: 600
        color: #DCFF4F

        +mediaMaxWidth(450px)
          font-size: 4.2rem

      .text
        margin-top: 2.2rem
        font-family: $default_font
        font-size: 2.2rem
        font-weight: 500
        line-height: 3.2rem
        color: #C0C0C0

        +mediaMaxWidth(1065px)
          max-width: 90%
          margin: 2.2rem auto 0

    &--right
      position: absolute
      bottom: -.5rem
      right: 5.6rem

      +mediaMaxWidth(1220px)
        max-width: 45rem
      //right: 250px

      +mediaMaxWidth(1065px)
        bottom: -2.5rem
        right: 50%
        transform: translateX(50%)
        display: flex
        justify-content: center
        width: 100%
        margin: 0 auto
        max-width: 20rem

      .decorative-image
        width: 100%
        height: auto
