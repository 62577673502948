.hero
  width: 100%
  margin: 4rem 0 6rem

  +mediaMinWidth(992px)
    margin: 7rem 0 10rem

  &__container
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    margin: 0 auto

    +mediaMinWidth(992px)
      flex-direction: row

    &--left
      height: fit-content
      width: 100%
      margin: 0 auto

      +mediaMinWidth(992px)
        max-width: calc(100% - 46.5rem)

      .above-title
        margin-bottom: 1.6rem
        color: #2196F3
        font-family: $default_font
        font-size: 1.8rem
        font-weight: 600
        line-height: 2.4rem
        letter-spacing: 0.01em

      .title
        margin-bottom: 2.4rem
        color: #151515
        font-family: $default_font
        font-size: 3rem
        font-weight: 600
        line-height: 4.2rem

        +mediaMinWidth(521px)
          font-size: 4.4rem
          line-height: 5.6rem

        +mediaMaxWidth(991.98px)
          br
            display: none

        +mediaMinWidth(1080px)
          font-size: 6.4rem
          line-height: 7.6rem

      .subtitle
        margin-bottom: 2.4rem
        color: #222222
        font-family: $default_font
        font-size: 2.2rem
        font-weight: 500
        line-height: 3.2rem

      .content
        color: #5F5F5F
        font-family: $default_font
        font-size: 1.6rem
        font-weight: 400
        line-height: 2.4rem

      .ctas
        display: flex
        flex-direction: column
        margin-top: 4rem

        +mediaMinWidth(521px)
          flex-direction: row

        .learn-more,
        .book-demo
          padding: 1.7rem 2.4rem
          min-width: 15.5rem
          width: 100%
          max-width: 100%
          background-color: $color_white
          border-radius: .8rem
          border: .2rem solid
          text-align: center
          transition: 300ms all ease-out

          +mediaMinWidth(521px)
            width: max-content

          & + .learn-more,
          & + .book-demo
            margin-top: 2rem

            +mediaMinWidth(521px)
              margin-top: 0
              margin-left: 2rem

          &.cta-border-blue
            border-color: #041834
            color: #041834

            &:hover
              border-color: #B2ADE3
              color: #B2ADE3
              background-color: #FFFFFF80

          &.cta-bg-blue
            border-color: #041834
            background-color: #041834
            color: $color_white

            &:hover
              border-color: #B2ADE3
              background-color: #B2ADE3

    &--right
      display: flex
      justify-content: center
      align-items: center
      width: 100%
      height: 40rem
      margin-top: 4rem
      border-radius: 2.4rem
      border: .4rem solid #DCFF4F
      overflow: hidden

      +mediaMinWidth(992px)
        min-width: 43.5rem
        max-width: 43.5rem
        height: 100%
        margin-top: 0
        margin-left: 4rem

      .image-container
        width: 100%

        img
          width: 100%
          height: 100%
          border-radius: 0
          object-fit: cover
          margin-bottom: -.5rem
