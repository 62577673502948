.customers
  width: 100%
  margin: 6rem 0 0

  +mediaMinWidth(992px)
    margin: 10rem 0 0

  .title
    color: #222222
    font-family: $default_font
    font-size: 3.2rem
    font-weight: 600
    line-height: 4rem
    text-align: center

    +mediaMinWidth(992px)
      font-size: 4rem
      line-height: 5.2rem

  .images
    margin: 3.4rem auto 0
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    gap: 3.5rem

    +mediaMinWidth(992px)
      margin-top: 6.4rem

    &--item
      width: max-content
      max-width: 12rem
      height: auto
      display: flex
      justify-content: center
      align-items: center

      img
        width: 100%
        max-height: 5rem
        object-fit: contain
