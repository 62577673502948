.post-card
  width: 100%
  display: flex
  flex-direction: column
  align-items: center
  border-radius: 2.4rem
  overflow: hidden
  background-color: #fff
  position: relative
  border: .2rem solid #000

  +mediaMinWidth(621px)
    width: calc(100% / 2 - 1rem)

  +mediaMinWidth(992px)
    width: calc(100% / 3 - 1.4rem)

  &:nth-child(even)
    background-color: #D3EAFD

  &__image
    width: 100%
    height: 19.2rem
    overflow: hidden

    .image
      width: 100%
      height: 100%
      object-fit: cover

    &.logo
      margin: 3.6rem 0 2.4rem
      height: auto

      img
        width: 100%
        height: 9.6rem
        object-fit: contain

    &.interview
      margin: 3.6rem 0 4.4rem
      height: auto
      text-align: center

      img
        width: 9.6rem
        height: 9.6rem
        border-radius: 50%

  &__content
    width: 100%
    padding: 3.6rem 3.6rem 0
    margin-bottom: 4rem

    &--categories-date
      display: flex
      align-items: center
      align-content: center

    &--categories,
    &--date
      display: flex
      align-items: center
      align-content: center
      color: #2196F3
      font-family: $default_font
      font-size: 1.6rem
      font-weight: 500
      line-height: 1.6rem
      letter-spacing: 0.01em

    &--categories
      & + .post-card__content--date
        &:before
          content: '•'
          display: flex
          margin: 0 .4rem

    &--title
      margin: 2.2rem 0 0 0
      font-family: $default_font
      font-size: 2.8rem
      font-weight: 500
      line-height: 3.6rem
      letter-spacing: 0

    &--excerpt
      margin: 2.4rem 0 0 0
      font-family: $default_font
      font-size: 1.8rem
      font-weight: 400
      line-height: 2.8rem
      letter-spacing: 0
      color: #5F5F5F

  &__cta
    width: 100%
    margin-top: auto
    padding: 0 3.6rem 3.6rem
    display: flex
    justify-content: flex-end

    a
      padding: 1.7rem 2.4rem
      color: #FFF
      font-family: $default_font
      font-size: 1.6rem
      font-weight: 500
      line-height: 2rem
      letter-spacing: 0
      background-color: #2196F3
      border-radius: .8rem
      transition: all 300ms ease-out

      &:hover
        background-color: #072754
        color: $color_white


.load_more_post, #load_more_post,
.load_more_image, #load_more_image, #loading, #no_data
  font-size: 1em
  padding: 1em 2.5em
  line-height: 1em
  border-radius: 3.5em
  background-color: #FFF
  border: .1rem solid #eee
  display: table
  text-align: center
  font-weight: 600
  cursor: pointer
  margin: 1.5rem auto

.container-post
  position: relative

